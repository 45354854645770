import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as CSS from "./Krok6Form.CSS";
import axios from "axios";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import Krok6Schema from "./Krok6Schema";
import { RaportInfo } from "../../../../components/common/RaportInfo/RaportInfo";
import { createSearchParams, useNavigate } from "react-router-dom";

const Krok6Form = ({ apiUrl, prevKrok, setActiveTab, queryParams }) => {
   const [formData, setFormData] = useState({
    initial_value: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [raportPage, setRaportPage] = useState(false);
  const [raportPageContent, setRaportPageContent] = useState(null);

  const navigate = useNavigate();

   useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);

    axios
      .get(apiUrl)
      .then((response) => {
        const {
          initial_value,
        } = response.data;

        setFormData({
          initial_value: initial_value,
        });
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePrevKrok = (e) => {
    e.preventDefault();
    navigate({
      pathname: `${prevKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(prevKrok);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      await axios.post(apiUrl, values).then((response) => {
        setIsLoading(false);

        if (response.status === 200) {
          setRaportPageContent(response.data.raport_page_context);
          setRaportPage(true);
        }
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      imie_nazwisko: formData?.initial_value?.imie_nazwisko,
      adres_email: formData?.initial_value?.adres_email,
      numer_telefonu: formData?.initial_value?.numer_telefonu,
      accept_mailing: false,
    },
    enableReinitialize: true,
    validationSchema: Krok6Schema,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  if (raportPage) {
    return <RaportInfo raportPageContent={raportPageContent} />;
  }

  return (
    <>
      <CSS.FormContainer>
        <CSS.FormInnerContainer>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <CSS.Header>Dane klienta:</CSS.Header>
              <div style={{ flex: "4" }}></div>
            </CSS.FormGroupInner>
          </CSS.FormGroup>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="imie_nazwisko">Imię i nazwisko:</label>
              <input
                name="imie_nazwisko"
                value={formik.values.imie_nazwisko}
                onChange={formik.handleChange("imie_nazwisko")}
              />
            </CSS.FormGroupInner>
            {formik.touched.imie_nazwisko && formik.errors.imie_nazwisko ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.imie_nazwisko}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="adres_email">Adres email:</label>
              <input
                name="adres_email"
                value={formik.values.adres_email}
                onChange={formik.handleChange("adres_email")}
              />
            </CSS.FormGroupInner>
            {formik.touched.adres_email && formik.errors.adres_email ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>{formik.errors.adres_email}</CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="numer_telefonu">Numer telefonu:</label>
              <input
                name="numer_telefonu"
                value={formik.values.numer_telefonu}
                onChange={formik.handleChange("numer_telefonu")}
              />
            </CSS.FormGroupInner>
            {formik.touched.numer_telefonu && formik.errors.numer_telefonu ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.numer_telefonu}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <div style={{ flex: "2", marginRight: "26px" }}></div>
              <CSS.TermsContainer>
                <input
                  name="accept_mailing"
                  id="accept_mailing"
                  type="checkbox"
                  value={formik.values.accept_mailing}
                  onChange={formik.handleChange("accept_mailing")}
                />
                <label htmlFor="accept_mailing">
                  Wyrażam zgodę na otrzymanie oferty oraz informacji handlowych
                  od FotonLab za pośrednictwem maila oraz drogą sms-ową.
                </label>
              </CSS.TermsContainer>
            </CSS.FormGroupInner>
            {formik.touched.accept_mailing && formik.errors.accept_mailing ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.accept_mailing}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <div style={{ flex: "2", marginRight: "26px" }}></div>
              <CSS.SubmitButtonContainer>
                <CSS.SubmitButton onClick={formik.handleSubmit}>
                  Wyślij zapytanie o ofertę
                </CSS.SubmitButton>
              </CSS.SubmitButtonContainer>
            </CSS.FormGroupInner>
          </CSS.FormGroup>
        </CSS.FormInnerContainer>
      </CSS.FormContainer>

      <CSS.ButtonGroup>
        <CSS.Button onClick={handlePrevKrok}>Poprzedni krok</CSS.Button>
      </CSS.ButtonGroup>
    </>
  );
};

export default Krok6Form;
